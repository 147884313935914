import React from "react"
import "./contact.css"

export default function Error() {
  return (
    <div className="contact-home">
      <div className="contact-title-container">
        <div className="contact-title">
          404
        </div>
      </div>
    </div>
  )
}